let $ = require('jquery');
window.$ = window.jQuery = $;
require('hammerjs');
require('popper.js');
require('bootstrap');
var waypoints = require('./waypoints');
require('jquery-validation');
/* If needed, add other dependencies here.

   Remember:
   - Use npm or yarn to install them.
   - Require them here, like `require('bootstrap');`
   - Leave the `$ = window.$` before the requires.
*/
$ = window.$;
  //confirm form 
    $('#contact-form').validate({
        messages: {
            name: {required: "This field is required"},
            email: {required: "This field is required" , email: "Please enter a valid email address"},
            company: {required: "This field is required"}
        },
        submitHandler: function() { 
           
          $('.container-form').addClass('hide');
          $('.msj-submit').addClass('show');
          setTimeout(function() {
            $('.msj-submit').removeClass('show');
            $('.container-form').removeClass('hide');
            $('#contact-form input').val('');
          }, 8000);
      
        }
    });
  //end confirm form 
  //scroll animations 
  if(window.innerWidth > 1025) {   
    $('section .col-12').addClass('animBlock');
    $('.bg-black').addClass('animBlock'); 
    $('#brands-container .container').addClass('animBlock'); 
    $('#media-container .container').addClass('animBlock'); 

    $('#brands-container').waypoint(function() {
        $('#brands-container .container').addClass('viewed');
    }, { offset: '80%' });

    $('#discovery-brands .container').waypoint(function() {
        $('#discovery-brands .container .col-12').addClass('viewed');
        setTimeout(function() {
           // toggle img card 1
          function toggleBrandsCard1(next) {
            var $this = $(this);
            $('#discovery-brands .card-1 div').each(function (i, elem) {
              $this.queue(function (n) {
                $(elem).addClass('selected').next().addClass('next');
                n();
                if ($('#discovery-brands .card-1 .image-3').hasClass('selected')) {
                  $('#discovery-brands .card-1 .image-1').addClass('next');
                }else{$('#discovery-brands .card-1 .image-1').removeClass('next');
                }
              }).delay(2900).queue(function (n) {
                $(elem).removeClass('selected').next().removeClass('next');
                n();
              });
            });
            $this.queue(toggleBrandsCard1);
            next();
          }
          $('#discovery-brands .card-1 div').queue(toggleBrandsCard1);
          //end toggle iimg card 1
        }, 2100);
        setTimeout(function() {
          // toggle img card 2
          function toggleBrandsCard2(next) {
            var $this = $(this);
            $('#discovery-brands .card-2 div').each(function (i, elem) {
              $this.queue(function (n) {
                $(elem).addClass('selected').next().addClass('next');
                n();
                if ($('#discovery-brands .card-2 .image-3').hasClass('selected')) {
                  $('#discovery-brands .card-2 .image-1').addClass('next');
                }else{$('#discovery-brands .card-2 .image-1').removeClass('next');
                }
              }).delay(2900).queue(function (n) {
                $(elem).removeClass('selected').next().removeClass('next');
                n();
              });
            });
            $this.queue(toggleBrandsCard2);
            next();
          }
          $('#discovery-brands .card-2 div').queue(toggleBrandsCard2);
          //end toggle iimg card 2
        }, 1900);
        setTimeout(function() {
          // toggle img card 3
          function toggleBrandsCard3(next) {
            var $this = $(this);
            $('#discovery-brands .card-3 div').each(function (i, elem) {
              $this.queue(function (n) {
                $(elem).addClass('selected').next().addClass('next');
                n();
                if ($('#discovery-brands .card-3 .image-3').hasClass('selected')) {
                  $('#discovery-brands .card-3 .image-1').addClass('next');
                }else{$('#discovery-brands .card-3 .image-1').removeClass('next');
                }
              }).delay(2900).queue(function (n) {
                $(elem).removeClass('selected').next().removeClass('next');
                n();
              });
            });
            $this.queue(toggleBrandsCard3);
            next();
          }
          $('#discovery-brands .card-3 div').queue(toggleBrandsCard3);
          //end toggle iimg card 3
        }, 2300);
    }, { offset: '40%' });

    $('#customized-homepage .container').waypoint(function() {
        $('#customized-homepage .container').addClass('viewed');
    }, { offset: '70%' });

    $('#customer-insights .container').waypoint(function() {
        $('#customer-insights .container .col-12').addClass('viewed');
    }, { offset: '40%' });

    $('#media-container').waypoint(function() {
        $('#media-container .container').addClass('viewed');
    }, { offset: '80%' });

    $('#contact .container').waypoint(function() {
        $('#contact .container .col-12').addClass('viewed');
        $('.bg-black').addClass('viewed'); 
    }, { offset: '60%' });
  }else{
     setTimeout(function() {
       // toggle img card 1
      function toggleBrandsCard1(next) {
        var $this = $(this);
        $('#discovery-brands .card-1 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#discovery-brands .card-1 .image-3').hasClass('selected')) {
              $('#discovery-brands .card-1 .image-1').addClass('next');
            }else{$('#discovery-brands .card-1 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard1);
        next();
      }
      $('#discovery-brands .card-1 div').queue(toggleBrandsCard1);
      //end toggle iimg card 1
    }, 2100);
    setTimeout(function() {
      // toggle img card 2
      function toggleBrandsCard2(next) {
        var $this = $(this);
        $('#discovery-brands .card-2 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#discovery-brands .card-2 .image-3').hasClass('selected')) {
              $('#discovery-brands .card-2 .image-1').addClass('next');
            }else{$('#discovery-brands .card-2 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard2);
        next();
      }
      $('#discovery-brands .card-2 div').queue(toggleBrandsCard2);
      //end toggle iimg card 2
    }, 1900);
    setTimeout(function() {
      // toggle img card 3
      function toggleBrandsCard3(next) {
        var $this = $(this);
        $('#discovery-brands .card-3 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#discovery-brands .card-3 .image-3').hasClass('selected')) {
              $('#discovery-brands .card-3 .image-1').addClass('next');
            }else{$('#discovery-brands .card-3 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard3);
        next();
      }
      $('#discovery-brands .card-3 div').queue(toggleBrandsCard3);
      //end toggle iimg card 3
    }, 2300);
  }

$(document).ready(function(){
    
   //hero
    setTimeout(function() {

      //bg hero toggle color
      function toggleSelected(next) {
        var $this = $(this);
        
        $('.main-content .bg-change-color-G, .main-content .bg-change-color-Y, .main-content .bg-change-color-R').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.main-content .bg-change-color-R').hasClass('selected')) {
              $('.main-content .bg-change-color-G').addClass('next');
            }else{
              $('.main-content .bg-change-color-G').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        
        $this.queue(toggleSelected);
        next();
      }

      $('.bg').queue(toggleSelected);
      //end bg hero toggle color

      //cards hero toggle 
      function toggleSelectedCard(next) {
        var $this = $(this);  
        $('.hero .block-top .img-container div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.hero .block-top .img-container div.image-1').hasClass('selected')) {

              $('.hero .block-top .img-container div.image-3').addClass('next');

            }else{

              $('.hero .block-top .img-container div.image-3').removeClass('next');

            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });   
        $this.queue(toggleSelectedCard);
        next();
      }
      $('.hero .block-top .img-container >div').queue(toggleSelectedCard);

      function toggleSelectedCard2(next) {
        var $this = $(this);  
        $('.hero .block-right .img-container div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.hero .block-right .img-container div.image-1').hasClass('selected')) {

              $('.hero .block-right .img-container div.image-3').addClass('next');

            }else{

              $('.hero .block-right .img-container div.image-3').removeClass('next');

            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });     
        $this.queue(toggleSelectedCard2);
        next();
      }
      $('.hero .block-right .img-container >div').queue(toggleSelectedCard2);
      //end cards hero toggle 

      //bg hero toggle color
      function toggleSelected22(next) {
        var $this = $(this);
        
        $('.full-img-product div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.full-img-product div:last-child').hasClass('selected')) {
              $('.full-img-product div:first-child').addClass('next');
            }else{
              $('.full-img-product div:first-child').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        
        $this.queue(toggleSelected22);
        next();
      }

      $('.full-img-product div').queue(toggleSelected22);
      //end bg hero toggle color
      
    }, 4000);

    setTimeout(function() { 
    //cards text toggle 
      function toggleSelectedCardText(next) {
        var $this = $(this);
        $('.hero .block-top .card-title').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText);
        next();
      }
      $('.hero .block-top .card-title').children('h6').queue(toggleSelectedCardText);

       function toggleSelectedCardText2(next) {
        var $this = $(this);
        $('.hero .block-top div .price').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText2);
        next();
      }
      $('.hero .block-top div .price').children('h6').queue(toggleSelectedCardText2);

      function toggleSelectedCardText3(next) {
        var $this = $(this);
        $('.hero .block-top div .likes').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText3);
        next();
      }
      $('.hero .block-top div .likes').children('h6').queue(toggleSelectedCardText3);
      //end card text toggle 
       //card 2 text toggle 
      function toggleSelectedCardText4(next) {
        var $this = $(this);
        $('.hero .block-right .card-title').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText4);
        next();
      }
      $('.hero .block-right .card-title').children('h6').queue(toggleSelectedCardText4);

       function toggleSelectedCardText5(next) {
        var $this = $(this);
        $('.hero .block-right div .price').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText5);
        next();
      }
      $('.hero .block-right div .price').children('h6').queue(toggleSelectedCardText5);

      function toggleSelectedCardText6(next) {
        var $this = $(this);
        $('.hero .block-right div .likes').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText6);
        next();
      }
      $('.hero .block-right div .likes').children('h6').queue(toggleSelectedCardText6);
      //end card 2 text toggle 
    }, 1200);
//preload
    setTimeout(function() { 
      $('body').removeClass('preload').addClass('animated');
    }, 600);
    setTimeout(function() {
      $('body').removeClass('animated');
    }, 4000);


});


// scroll to 
  function getRelatedContent(el){
    return $($(el).attr('href'));
  }
  function getRelatedNavigation(el){
    return $('.btn-scroll[href=#'+$(el).attr('id')+']');
  }

  $('.btn-scroll').on('click touch',function(e){
    e.preventDefault();
    $('html,body').animate({scrollTop:getRelatedContent(this).offset().top }, 1000);
  });